import React from 'react';
import { styled } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwFeaturedSection } from '@lib/components/SwFeaturedSection';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import { AspectRatios } from '@lib/enums/apectRatios';

const LogoContainer = styled(SwPaper)`
    aspect-ratio: ${AspectRatios.square};
    height: 64px;
`;

export const SectionFeaturedStory: React.FC<{ featuredStory: any }> = ({ featuredStory }) => {
    return (
        <SwFeaturedSection
            abstract={featuredStory.abstract}
            category={featuredStory.category}
            coverImage={featuredStory.company.photos[0]}
            meta={
                <LogoContainer backgroundColor={'transparent'} overflow={'unset'} padding={1}>
                    <GatsbyImage
                        alt={featuredStory.company.logo.alt}
                        image={featuredStory.company.logo.gatsbyImageData}
                        imgStyle={{ objectFit: 'contain' }}
                        objectFit={'contain'}
                        style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                    />
                </LogoContainer>
            }
            showLabel={false}
            slug={`/${featuredStory.slug}`}
            tags={featuredStory.tags}
            title={featuredStory.title}
        />
    );
};
