import React from 'react';
import { Box, Grid, GridSize } from '@mui/material';
import SwCompanyLogo from '../../../lib/components/bedrock/SwCompanyLogo';

export const SwCompaniesLogosSection: React.FunctionComponent<SwCompaniesLogosSectionProps> = (props) => {
    const { logos, nbLines, mobile } = props;

    const lines = logos.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / (logos.length / nbLines));
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);

    return (
        <Grid container={true} spacing={2}>
            {lines.map((logos, index) => {
                return (
                    <Grid item={true} key={`line-${index}`} md={12} xs={Math.round(12 / nbLines) as GridSize}>
                        <Box display={'flex'} flexDirection={mobile ? 'column' : 'row'} width={'100%'}>
                            <>
                                {logos.map((logo: LogoProperties, index) => {
                                    return (
                                        <Box
                                            key={`logo-${index}`}
                                            mr={index < logos.length - 1 ? 2 : 0}
                                            mt={index > 0 && mobile ? 2 : 0}
                                            width={mobile ? '100%' : `${100 / logos.length}%`}
                                        >
                                            <SwCompanyLogo
                                                loading={logo.loading}
                                                logoHeight={logo.height}
                                                logoSrc={logo.src}
                                                logoWidth={logo.width}
                                            />
                                        </Box>
                                    );
                                })}
                            </>
                        </Box>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export interface SwCompaniesLogosSectionProps {
    logos: LogoProperties[];
    nbLines: number;
    mobile: boolean;
}

export interface LogoProperties {
    src: string;
    height?: string | number;
    loading?: 'eager' | 'lazy';
    width?: string | number;
}
