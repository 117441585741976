import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { css, styled } from '@mui/material';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import { grey3, grey5, grey6, white } from './bedrock/SwColors';
import SwButton, { SwButtonKind } from './bedrock/button/SwButton';
import SwFlexBox from './bedrock/layout/SwFlexBox';
import { SwLinkBox, SwLinkOverlay } from './bedrock/navigation/SwLinkBox';
import SwTypography from './bedrock/typography/SwTypography';

export type SwCarouselCustomersProps = {
    customers: {
        text: string;
        target?: {
            slug: string;
        };
        author: {
            firstName: string;
            lastName: string;
            role: string;
            portrait: {
                alt: string;
                gatsbyImageData: GatsbyImageProps['image'];
            };
            company: {
                logo: {
                    alt: string;
                    gatsbyImageData: GatsbyImageProps['image'];
                };
            };
        };
    }[];
};

const ContentContainer = styled('div')`
    background: ${white};
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
    border-left: 1px solid ${grey5};
    border-bottom: 0;

    & > p:first-child {
        flex: 1;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        border-left: 0;
        border-bottom: 1px solid ${grey5};
    }
`;

const ImageContainer = styled('div')`
    overflow: hidden;
    position: relative;
    background: ${grey5};
    z-index: 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        aspect-ratio: 4/3;
    }
`;

const Card = styled('div')<{ interactive?: boolean }>`
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    z-index: 0;
    display: grid;
    border: 1px solid ${grey5};
    border-radius: 8px;
    grid-template-columns: 50% 50%;
    min-height: 380px;
    grid-template-rows: 1fr;

    & .sw-carousel-customers__image-container > div[data-gatsby-image-wrapper] {
        transform: scale(1);
        transition: transform 0.25s ease-out;
    }

    & .sw-carousel-customers__content-container {
        background: white;
        transition: background 0.25s ease-out;
    }

    ${({ interactive }) =>
        interactive
            ? css`
                  cursor: pointer;

                  @supports selector(:has(*)) {
                      &:hover:not(:has(button:hover)) {
                          & .sw-carousel-customers__image-container > div[data-gatsby-image-wrapper] {
                              transform: scale(1.1);
                          }

                          & .sw-carousel-customers__content-container {
                              background: ${grey6};
                          }
                      }
                  }

                  @supports not selector(:has(*)) {
                      &:hover {
                          & .sw-carousel-customers__image-container > div[data-gatsby-image-wrapper] {
                              transform: scale(1.1);
                          }

                          & .sw-carousel-customers__content-container {
                              background: ${grey6};
                          }
                      }
                  }
              `
            : ''}

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
    }
`;

const CompanyIcon = styled('div')`
    position: absolute;
    left: 16px;
    bottom: 16px;
    background: ${white};
    border-left: 1px solid ${grey5};
    border-radius: 8px;
    padding: 16px;
    max-width: 139px;
    display: grid;
    width: 100%;
    aspect-ratio: 16/9;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        max-width: 90px;
    }
`;

export const SwCarouselCustomers: React.FC<SwCarouselCustomersProps> = (props) => {
    const { customers } = props;

    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = customers.length;

    const { author, text, target } = customers[activeStep];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1 === maxSteps ? 0 : prevActiveStep + 1));
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1));
    };

    return (
        <SwLinkBox>
            <Card interactive={Boolean(target?.slug)}>
                <ImageContainer className={'sw-carousel-customers__image-container'}>
                    {author.portrait && (
                        <GatsbyImage
                            alt={author.portrait.alt}
                            image={author.portrait.gatsbyImageData}
                            objectFit={'cover'}
                            style={{ height: '100%', width: '100%' }}
                        />
                    )}
                    <CompanyIcon>
                        <GatsbyImage
                            alt={author.company.logo.alt}
                            image={author.company.logo.gatsbyImageData}
                            objectFit={'contain'}
                            style={{
                                height: '100%',
                                width: '100%',
                                maxWidth: '100%',
                                aspectRatio: '18/5'
                            }}
                        />
                    </CompanyIcon>
                </ImageContainer>
                <ContentContainer className={'sw-carousel-customers__content-container'}>
                    <SwTypography bold={true} color={grey3} component={'p'} variant={'h4'}>
                        {target ? <SwLinkOverlay to={`/${target.slug}`}>{text}</SwLinkOverlay> : text}
                    </SwTypography>
                    <SwFlexBox alignItems={'center'} gap={16} justifyContent={'space-between'} width={'100%'}>
                        <SwFlexBox alignItems={'start'} flexDirection={'column'}>
                            <SwTypography bold={true} variant={'body2'}>
                                {author.firstName} {author.lastName}
                            </SwTypography>
                            <SwTypography variant={'body2'}>{author.role}</SwTypography>
                        </SwFlexBox>
                        {maxSteps > 1 && (
                            <SwFlexBox gap={8}>
                                <SwButton
                                    ariaLabel={'back'}
                                    kind={SwButtonKind.Secondary}
                                    size={'small'}
                                    onClick={handleBack}
                                >
                                    <CaretLeft />
                                </SwButton>
                                <SwButton
                                    ariaLabel={'next'}
                                    kind={SwButtonKind.Secondary}
                                    size={'small'}
                                    onClick={handleNext}
                                >
                                    <CaretRight />
                                </SwButton>
                            </SwFlexBox>
                        )}
                    </SwFlexBox>
                </ContentContainer>
            </Card>
        </SwLinkBox>
    );
};
