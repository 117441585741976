import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwCarouselCustomers } from '@lib/components/SwCarouselCustomers';
import { SwEmphasizedText } from '@lib/components/SwEmphasizedText';
import SwEmptySection from '@lib/components/SwEmptySection';
import { SwLatestContent } from '@lib/components/SwLatestContent';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { black, grey3, grey5 } from '@lib/components/bedrock/SwColors';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwSpacer from '@lib/components/bedrock/layout/SwSpacer';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import SwWebsiteContainer from '@lib/components/layout/SwWebsiteContainer';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { CDNClient } from '@lib/helpers/image';
import FlashBanner from '@app/common/components/FlashBanner';
import Seo from '@app/common/components/Seo';
import { SectionFeaturedStory } from '@app/story/SectionFeaturedStory';
import SwLayout from '../components/v1/SwLayout';
import SwLine from '../components/v1/SwLine';
import { SwCompaniesLogosSection } from '../components/v1/home/SwCompaniesLogo';
import SwPagination from '../components/v1/pagination/SwPagination';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';

const Header = styled('div')`
    padding-block-start: 32px;
    padding-block-end: 64px;
`;

const CustomerStories = ({ data, pageContext }) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const { title, subtitle } = data.page;
    const { currentPage, pageCount } = data.allGraphCmsCustomerStory.pageInfo;
    const stories = data.allGraphCmsCustomerStory.nodes?.map(({ company, ...rest }) => ({
        ...rest,
        company,
        coverImage: {
            alt: company.images?.[0]?.alt,
            gatsbyImageData: company.images?.[0]?.gatsbyImageData
        }
    }));

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.resources.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwWebsiteContainer>
                <SwLayout>
                    <SwContainer>
                        <Header>
                            <SwGrid columns={{ xs: ['100%'], md: ['60%', 'auto'] }} gap={32}>
                                <SwTypography color={grey3} component={'h1'} variant={'h3'}>
                                    <SwTypography color={black} component={'span'} variant={'h3'}>
                                        {title}.
                                    </SwTypography>{' '}
                                    {subtitle}
                                </SwTypography>
                            </SwGrid>
                        </Header>
                    </SwContainer>
                    <SectionFeaturedStory featuredStory={data.pinnedCustomerStory.nodes[0]} />
                    <SwContainer>
                        <Box display={'flex'} flexDirection={'column'} pt={4} width={'100%'}>
                            <div>
                                {stories.length > 0 ? (
                                    <SwGrid columns={{ xs: 1, sm: 2, md: 3 }}>
                                        {stories.map((story) => (
                                            <SwRecordCard
                                                key={story.id}
                                                meta={
                                                    <GatsbyImage
                                                        alt={story.company.logo.alt}
                                                        image={story.company.logo.gatsbyImageData}
                                                        objectFit={'contain'}
                                                        style={{ height: '100%', width: '100%' }}
                                                    />
                                                }
                                                record={story}
                                                titleHeading={'h2'}
                                            />
                                        ))}
                                    </SwGrid>
                                ) : (
                                    <>
                                        <SwSpacer direction={'vertical'} spacing={12} />
                                        <SwEmptySection />
                                        <SwSpacer direction={'vertical'} spacing={16} />
                                    </>
                                )}
                                {pageCount > 1 && (
                                    <>
                                        <SwLine color={grey5} spacing={2} />
                                        <SwPagination
                                            basePath={pageContext.basePath}
                                            currentPage={currentPage ?? 1}
                                            pageCount={pageCount}
                                        />
                                    </>
                                )}
                                <SwLine color={grey5} spacing={4} />
                            </div>
                        </Box>
                    </SwContainer>

                    <SwFlexBox flexDirection={'column'} gap={theme.spacing(4)}>
                        <SwContainer gap={32}>
                            <SwEmphasizedText>{t('customers_page.quote_1')}</SwEmphasizedText>
                            <SwLine color={grey5} spacing={0} />
                            <SwCarouselCustomers customers={data.latestCustomerQuotes.customers} />
                            <SwLine color={grey5} spacing={0} />
                            <Box width={'100%'}>
                                <SwCompaniesLogosSection
                                    logos={[
                                        {
                                            src: CDNClient.buildURL('/logos/saint-gobain.png', {
                                                auto: 'format',
                                                h: 50
                                            }),
                                            height: 35,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/ubisoft-clean.svg', {
                                                auto: 'format'
                                            }),
                                            height: 28,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/dropp.png', {
                                                auto: 'format',
                                                h: 50
                                            }),
                                            height: 29,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/eyra.png', {
                                                auto: 'format',
                                                h: 50
                                            }),
                                            height: 39,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/qwetch.png', {
                                                auto: 'format',
                                                h: 50
                                            }),
                                            height: 31,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/coatue.png', {
                                                auto: 'format',
                                                h: 50
                                            }),
                                            height: 15,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/swisscom.png', {
                                                auto: 'format',
                                                h: 50
                                            }),
                                            height: 24,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/questel.webp', {
                                                auto: 'format',
                                                h: 50
                                            }),
                                            height: 25,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/mirova.png', {
                                                auto: 'format',
                                                h: 80
                                            }),
                                            height: 38,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/mediawan.png', {
                                                auto: 'format',
                                                h: 50
                                            }),
                                            height: 13,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/2050.png', {
                                                auto: 'format',
                                                h: 80
                                            }),
                                            height: 40,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/linedata.png', {
                                                auto: 'format',
                                                h: 70
                                            }),
                                            height: 34,
                                            width: 80
                                        },
                                        {
                                            src: 'https://sweep.imgix.net/4145/2b8e3a74-b737-4fa9-8442-c218a2e76560.png',
                                            height: 15,
                                            width: 80
                                        }, //Openclassroom
                                        {
                                            src: 'https://sweep.imgix.net/4145/3afca737-19e8-484b-96e7-54b013a4bf0a.png?h=60',
                                            height: 30,
                                            width: 80
                                        }, // Prose
                                        {
                                            src: CDNClient.buildURL('/logos/qima.png', {
                                                auto: 'format',
                                                h: 70
                                            }),
                                            height: 34,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/shine.png', {
                                                auto: 'format',
                                                h: 70
                                            }),
                                            height: 34,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/swoopin.png', {
                                                auto: 'format',
                                                h: 70
                                            }),
                                            height: 34,
                                            width: 80
                                        },
                                        {
                                            src: 'https://sweep.imgix.net/4145/96214df1-209a-4634-8bd6-8e75b2574b36.png',
                                            height: 35,
                                            width: 80
                                        }, //Withings
                                        {
                                            src: CDNClient.buildURL('/logos/zapp.png', {
                                                auto: 'format',
                                                h: 60
                                            }),
                                            height: 30,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/open.png', {
                                                auto: 'format',
                                                h: 60
                                            }),
                                            height: 30,
                                            width: 80
                                        },
                                        {
                                            src: CDNClient.buildURL('/logos/margo.png', {
                                                auto: 'format',
                                                h: 80
                                            }),
                                            height: 41,
                                            width: 80
                                        }
                                    ]}
                                    mobile={mobile}
                                    nbLines={3}
                                />
                            </Box>
                            <SwLine color={grey5} spacing={0} />
                        </SwContainer>
                        <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={16} width={'100%'}>
                            <SwContainer>
                                <SwFlexBox alignItems={'center'} gap={4}>
                                    <SwTypography bold={true} color={grey3} component={'h2'} variant={'body1'}>
                                        {t('blog')}
                                    </SwTypography>
                                </SwFlexBox>
                            </SwContainer>
                            <SwContainer hasOverflowingContent={true}>
                                <SwLatestContent
                                    content={data.latestArticles.nodes}
                                    renderItems={(article) => (
                                        <SwRecordCard
                                            baseUrl={WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()]?.link}
                                            key={article.id}
                                            record={article}
                                            titleHeading={'h3'}
                                            width={{ xs: 300, md: 'auto' }}
                                        />
                                    )}
                                />
                            </SwContainer>
                        </SwFlexBox>
                    </SwFlexBox>

                    <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({
    location: { pathname },
    pageContext,
    data: {
        page: { seo },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={pageContext.noIndex ?? seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);
export const pageQuery = graphql`
    query getCustomersPageInformation(
        $limit: Int
        $skip: Int
        $idToExclude: String
        $id: String
        $locale: GraphCMS_Locale
    ) {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        allGraphCmsCustomerStory(
            sort: { order: DESC, fields: published }
            limit: $limit
            skip: $skip
            filter: { locale: { in: [$locale] }, actualLocale: { in: [$locale] }, id: { ne: $idToExclude } }
        ) {
            nodes {
                ...CustomerStoryCard_GraphCMS_CustomerStory
            }
            pageInfo {
                currentPage
                pageCount
                perPage
                totalCount
            }
        }
        pinnedCustomerStory: allGraphCmsCustomerStory(
            sort: { order: DESC, fields: published }
            limit: 1
            filter: { locale: { eq: $locale }, actualLocale: { eq: $locale } }
        ) {
            nodes {
                ...PinnedCustomerStoryCard_GraphCMS_CustomerStory
            }
        }
        latestCustomerQuotes: graphCmsCustomersCarousel(name: { eq: "Main" }, locale: { eq: $locale }) {
            ...Essentials_GraphCMS_CustomersCarousel
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        enPage: graphCmsPage(slug: { eq: "customers" }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        page: graphCmsPage(id: { eq: $id }) {
            id
            remoteId
            slug
            seo {
                ...Essentials_GraphCMS_Seo
            }
            title
            subtitle
        }
    }
`;

export default CustomerStories;
