import React from 'react';
import { Link, styled } from '@mui/material';

interface SwConditionalLinkWrapperProps {
    children: React.ReactNode;
    className?: string;
    src?: string;
}

const StyledLink = styled(Link)`
    display: flex;
    height: inherit;
    width: inherit;
    text-decoration: none;
`;

const SwConditionalLinkWrapper: React.FC<SwConditionalLinkWrapperProps> = (props) => {
    const { children, className, src } = props;
    return src ? (
        <StyledLink className={className} href={src} rel={'noopener'} target={'_blank'}>
            {children}
        </StyledLink>
    ) : (
        <>{children}</>
    );
};

export default SwConditionalLinkWrapper;
