import React from 'react';
import { Box, styled } from '@mui/material';
import SwConditionalLinkWrapper from '../../../components/v1/website/SwConditionalLinkWrapper';
import { isDefined } from '../../helpers/object';
import { white, grey5 } from './SwColors';

const StyledImg = styled('img')`
    max-width: 100%;
    block-size: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const SwCompanyLogo: React.FunctionComponent<SwCompanyLogoProps> = (props) => {
    const { src, logoSrc, logoHeight, height, bgColor, fullWidth, logoWidth, loading } = props;

    return (
        <Box
            bgcolor={bgColor ?? white}
            border={`1px solid ${grey5}`}
            borderRadius={'8px'}
            display={'flex'}
            flexDirection={'column'}
            height={isDefined(height) ? height : '76px'}
            px={2}
            width={isDefined(fullWidth) ? '100%' : 'auto'}
        >
            <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'center'} width={'100%'}>
                <Box
                    alignItems={'center'}
                    display={'flex'}
                    flexDirection={'column'}
                    height={isDefined(logoHeight) ? logoHeight : '50px'}
                    justifyContent={'center'}
                >
                    <SwConditionalLinkWrapper src={src}>
                        <StyledImg
                            alt={'company logo'}
                            decoding={'async'}
                            height={logoHeight}
                            loading={loading ?? 'lazy'}
                            src={logoSrc}
                            width={logoWidth}
                        />
                    </SwConditionalLinkWrapper>
                </Box>
            </Box>
        </Box>
    );
};

export interface SwCompanyLogoProps {
    logoSrc: string;
    src?: string;
    height?: string;
    py?: number;
    px?: number;
    bgColor?: string;
    fullWidth?: boolean;
    logoHeight?: string | number;
    logoWidth?: string | number;
    loading?: 'eager' | 'lazy';
}

export default SwCompanyLogo;
