import React from 'react';
import { styled } from '@mui/material';
import { grey3 } from './bedrock/SwColors';
import SwTypography, { SwTypographyProps } from './bedrock/typography/SwTypography';

export type SwEmphasizedTextProps = SwTypographyProps & {
    text?: string;
    titleHeading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
};

const StyledText = styled(SwTypography)`
    inline-size: 60%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        inline-size: 100%;
    }
`;

export const SwEmphasizedText: React.FC<SwEmphasizedTextProps> = (props) => {
    const { children, titleHeading, component = 'p', color = grey3, variant = 'h3', text, ...rest } = props;

    return (
        <StyledText color={color} component={titleHeading || component} variant={variant} {...rest}>
            {text || children}
        </StyledText>
    );
};
